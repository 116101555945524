import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Emoji from '../components/Emoji';
import RowMenu from '../components/RowMenu';

const MENU_ENTRIES = [
    { label: 'Projects', icon: 'code-branch', path: '/projects' },
    { label: 'Games', icon: 'gamepad', path: '/games' },
    { label: 'GitHub', icon: ['fab', 'github'], href: "https://github.com/ryanrossiter" },
    { label: 'LinkedIn', icon: ['fab', 'linkedin-in'], href: "https://www.linkedin.com/in/rossitr" }
];

const IndexView = () => (
    <Fragment>
        <Container className="mt-3">
            <Row>
                <Col>
                    <ul className="list-unstyled list-flex">
                        <li>
                            <Emoji symbol="👨‍🎓 " className="mr-3" />
                            <small>Bachelor of Computing (Queen's University)</small>
                        </li>
                        <li>
                            <Emoji symbol="🥇" className="mr-3" />
                            <small style={{display: 'block'}}>
                                Kingston Mayor's Innovation Challenge Winner 2019{' '}
                                <a target="_blank"
                                    href="https://www.cityofkingston.ca/-/three-student-teams-win-internships-at-2nd-annual-mayor-s-innovation-challenge?redirect=%2Fcity-hall%2Fnews-public-notices">
                                    <FontAwesomeIcon icon="external-link-alt" />
                                </a>
                            </small>
                        </li>
                        <li>
                            <Emoji symbol="🤓" className="mr-3" />
                            <small>JavaScript Nerd</small>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
        <RowMenu entries={MENU_ENTRIES} />
    </Fragment>
);

export default IndexView;