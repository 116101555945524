import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import RowMenu from '../components/RowMenu';

const ShapeExtrusionZdog = ({ backPath }) => (
    <Fragment>
        <RowMenu entries={[
            { label: 'GitHub Project', icon: ['fab', 'github'], href: "https://github.com/ryanrossiter/shape-extrusion-zdog" },
        ]} />
        <Container className="mt-2">
            <Row>
                <Col>
                    <p>Demonstrating shape extrusion using <a href="https://github.com/metafizzy/zdog">Zdog</a>
                    <br/>
                    (+<a href="https://github.com/drcmda/react-zdog">react-zdog</a> bindings)</p>
                </Col>
            </Row>
        </Container>
    </Fragment>
);

export default ShapeExtrusionZdog;