import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Scrollbars } from 'react-custom-scrollbars';

import BackMenuRow from '../components/BackMenuRow';

import Defs from '../Defs';

const SidebarHeader = ({ large, title }) => (
    <div className={`sidebar-header ${large? 'large':''}`}>
        <div className="sidebar-profile">
            <img alt="Profile Image" src={`${Defs.BASENAME}/assets/img/WhiteKidGoogling.png`} />
        </div>
        <div className="sidebar-details">
            Ryan Rossiter
            <small>{ title }</small>
        </div>
    </div>
);

const SidebarWrapper = ({ backPath, depth, children, in:_in, title='', showMobile=true }) => (
    <CSSTransition unmountOnExit timeout={1000} in={_in} classNames="slide">
        <div className={`sidebar-wrapper depth-${depth} ${!showMobile? 'min-height' : ''}`}>
            <SidebarHeader large={depth === 0} title={title} />
            <div className="sidebar-content">
                <Scrollbars className={showMobile? '':'hide-mobile'}>
                    { depth > 0? <BackMenuRow backPath={backPath} /> : null }
                    <div className="sidebar-content-inner">{ children }</div>
                </Scrollbars>
                { !showMobile && depth > 0? <div className="show-mobile"><BackMenuRow backPath={backPath} /></div> : null }
            </div>
        </div>
    </CSSTransition>
);

export default SidebarWrapper;
