import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import '../css/RowMenu.scss';

const RowEntry = ({ icon, label, showRightIcon=true, ext=false }) => (
    <Row>
        <Col xs={2} className="text-center">
            <FontAwesomeIcon icon={icon} />
        </Col>
        <Col>{ label }</Col>
        { showRightIcon? (
            <Col xs={1} className="text-center mr-4">
                <FontAwesomeIcon icon={ext? 'external-link-alt':'chevron-right'} />
            </Col>
        ) : null }
    </Row>
);

const RowMenu = ({ entries }) => (
    <Container className="row-menu">
        { entries.map(({ path, href, ...rest }, i) => {
            let key = 'row-menu' + i;
            if (path) {
                return (
                    <Link key={key} to={{ pathname: path }} className="link-unstyled">
                        <RowEntry {...rest} />
                    </Link>
                );
            } else if (href) {
                return (
                    <a key={key} className="link-unstyled" href={href} target="_blank" rel="noreferrer">
                        <RowEntry {...rest} ext />
                    </a>
                )
            }
            return undefined;
        })}
    </Container>
);

export default RowMenu;