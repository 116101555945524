import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import RowMenu from '../components/RowMenu';

const Projects = ({ backPath }) => (
    <RowMenu entries={[
        { label: 'Shape Extrusion Zdog Demo', icon: 'flask', path: "/projects/shape-extrusion-zdog" },
        { label: 'FractalKompute', icon: 'magic', path: "/projects/fractalkompute" },
    ]} />
);

export default Projects;