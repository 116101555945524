import React, { Fragment } from 'react';
import { HashRouter, Route, Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import './css/App.scss';

import Defs from './Defs';

import SidebarWrapper from './views/SidebarWrapper';
import IndexView from './views/IndexView';
import Projects from './views/Projects';
import Games from './views/Games';
import FractalKompute from './views/FractalKompute';
import ShapeExtrusionZdog from './views/ShapeExtrusionZdog';
import ChipsChallengeJs from './views/ChipsChallengeJs';
import Hex2048 from './views/Hex2048';
import WebView from './views/WebView';

import BackgroundIllustration from './components/BackgroundIllustration';

const supportsHistory = 'pushState' in window.history;

const routes = [
  { path: '/',
    SidebarComponent: IndexView },

  { path: '/games',
    SidebarComponent: Games, sidebarTitle: 'Games' },
  { path: '/games/chips-challenge-js',
    SidebarComponent: ChipsChallengeJs, sidebarTitle: 'Chips Challenge Clone',
    ContentComponent: WebView("https://ryanrossiter.com/chips-challenge-js") },
  { path: '/games/hex2048',
    SidebarComponent: Hex2048, sidebarTitle: 'Hex 2048',
    ContentComponent: WebView("https://ryanrossiter.com/hex2048/www/index_dev.html") },

  { path: '/projects',
    SidebarComponent: Projects, sidebarTitle: 'Projects' },
  { path: '/projects/fractalkompute',
    SidebarComponent: FractalKompute, sidebarTitle: 'FractalKompute',
    ContentComponent: WebView("https://ryanrossiter.com/FractalKompute") },
  { path: '/projects/shape-extrusion-zdog',
    SidebarComponent: ShapeExtrusionZdog, sidebarTitle: 'Shape Extrusion Zdog Demo',
    ContentComponent: WebView("https://ryanrossiter.com/shape-extrusion-zdog") },
];

const App = () => (
  <HashRouter forceRefresh={!supportsHistory}
    basename={Defs.BASENAME}>
    <div className="page-container">
      {routes.map(({ path, SidebarComponent, ContentComponent, depth, props, sidebarTitle }) => (
        <Route key={path} exact path={path}>{({ match, ...routeProps }) => {
          let _in = match != null;
          depth = (typeof depth === "number"? depth
            : (path.length === 1? 0 : path.split('/').length - 1));
          let backPath = '/' + path.split('/').slice(1, -1).join('/');

          return (
            <Fragment>
              {_in? [...Array(depth + (ContentComponent? 0 : 1))].map((n,i) =>
                <div className={`sidebar-edge depth-${i} ${path}-edge-${i}`} key={`${path}-edge-${i}`}/>) : null}
              {_in?<div className="sidebar-placeholder"/>:null}
              <SidebarWrapper depth={depth} in={_in}
                backPath={backPath} title={sidebarTitle} showMobile={!ContentComponent}>
                <SidebarComponent {...props} {...routeProps} />
              </SidebarWrapper>
            </Fragment>
          );
        }}</Route>
      ))}

      <div className="page-content">
        {routes.map(({ path, ContentComponent }) => (
          <Route key={path} exact path={path}>{({ match, ...routeProps }) =>
              ContentComponent? (
                <CSSTransition unmountOnExit timeout={1000} in={match != null}>
                  <div className="inner"><ContentComponent /></div>
                </CSSTransition>
              ) : null
          }</Route>
        ))}
        
        <BackgroundIllustration />
      </div>
    </div>
  </HashRouter>
);

export default App;
