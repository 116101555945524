import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const FractalKompute = ({ backPath }) => (
    <Container className="mt-2">
        <Row>
            <Col>
                <p>Here is some info about FractalKompute</p>
            </Col>
        </Row>
    </Container>
);

export default FractalKompute;