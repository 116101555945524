import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import RowMenu from '../components/RowMenu';

const Games = ({ backPath }) => (
    <RowMenu entries={[
        { label: 'Hex 2048', icon: 'mobile-alt', path: "/games/hex2048" },
        { label: 'Chip\'s Challenge Clone', icon: 'microchip', path: "/games/chips-challenge-js" },
    ]} />
);

export default Games;