import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faChevronRight, faChevronLeft, faExternalLinkAlt, faMagic, faMicrochip, faGamepad, faMobileAlt, faCodeBranch, faFlask } from '@fortawesome/free-solid-svg-icons';

library.add(
    faGithub,
    faLinkedinIn,
    faChevronRight,
    faChevronLeft,
    faExternalLinkAlt,
    faMagic,
    faMicrochip,
    faGamepad,
    faMobileAlt,
    faCodeBranch,
    faFlask);

ReactDOM.render(
    <App />,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// Can't use because of hashrouter + gh-pages
serviceWorker.unregister();
