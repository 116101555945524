import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Hex2048 = ({ backPath }) => (
    <Container className="mt-2">
        <Row>
            <Col>
                <p>Here is some info about Hex2048</p>
            </Col>
        </Row>
    </Container>
);

export default Hex2048;