import { TAU, lerp, easeInOut } from 'zdog'
import React, { useRef } from 'react'
import { Illustration, Anchor, Shape, Box, useRender, useZdog } from 'react-zdog'

import useWindowDimensions from '../hooks/useWindowDimensions';

const side = [[-1, -1, 1], [-1, 0, 1], [-1, 1, 1], [0, -1, 1], [0, 1, 1], [1, -1, 1], [1, 0, 1], [1, 1, 1]]
const middle = [[1, 1, 0], [1, -1, 0], [-1, 1, 0], [-1, -1, 0]]
const frames = [[0, 0, 0], [0, 0, TAU / 4], [-TAU / 4, 0, TAU / 4], [-TAU / 4, 0, TAU / 2]];

function Dots({ coords, ...props }) {
  return (
    <Anchor {...props}>
      {coords.map(([x, y, z], index) => {
        let m = Math.abs(x) + Math.abs(y) + Math.abs(z);
        let v = m === 3;
        let color = (v ? 'rgba(72, 132, 189, 0.3)' : 'rgba(53, 53, 53, 0.1)');
        let scale = (v ? 0.4 : 0.8);

        return (
          <Box key={index} color={color} stroke={false} scale={scale} translate={{ x, y, z }} />
        );
      })}
    </Anchor>
  )
}

function MainElement() {
  let ticker = 0
  let cycleCount = 90
  let turnLimit = frames.length - 1
  let ref = useRef(undefined)
  useRender(() => {
    let progress = (ticker++) / cycleCount;
    let tween = easeInOut(progress % 1, 3)
    let turn = Math.floor(progress % turnLimit)
    ref.current.rotate.x = lerp(frames[turn][0], frames[turn + 1][0], tween)
    ref.current.rotate.y = lerp(frames[turn][1], frames[turn + 1][1], tween)
    ref.current.rotate.z = lerp(frames[turn][2], frames[turn + 1][2], tween)
  })
  return (
    <Anchor ref={ref} scale={8}>
      <Dots coords={side} translate={{ z: 0 }} rotate={{ y: 0 }} />
      <Dots coords={middle} />
      <Dots coords={side} translate={{ z: 0 }} rotate={{ x: TAU / 2 }} />
    </Anchor>
  )
}

export default function BackgroundIllustration() {
  const { height, width } = useWindowDimensions();
  let onPrerender = (ctx) => {
    ctx.globalCompositeOperation = 'lighter';
    // ctx.globalCompositeOperation = 'difference';
  }

  return width > 875? (
    <Illustration onPrerender={onPrerender} className="bg-illustration" rotate={{ x: (TAU * -35.264 /*magic*/) / 360, y: (TAU * 1) / 8 }} element="canvas" zoom={20}>
      <MainElement />
    </Illustration>
  ) : null;
}